<template>
  <div class="page-info-content">
    <el-tabs v-model="activeName">
      <!-- 基本信息 -->
      <el-tab-pane label="公司信息" name="first">
        <el-descriptions :colon="false" class="margin-top" title="基本信息" :column="2" :size="''">
          <template v-if="controlButton" slot="extra">
            <el-button type="primary" size="small" @click="confirmAudit">
              审核
            </el-button>
          </template>
          <el-descriptions-item label="公司编号">
            {{ memberInformation.cmpCode }}
          </el-descriptions-item>
          <el-descriptions-item label="公司名称">
            {{ memberInformation.cmpName }}
          </el-descriptions-item>
          <el-descriptions-item label="公司简称">
            {{ memberInformation.cmpShortName }}
          </el-descriptions-item>
          <el-descriptions-item label="授权系统">
            {{ memberInformation.cmpRole }}
          </el-descriptions-item>
          <el-descriptions-item label="注册资本(万元)">
            {{ memberInformation.cmpRegFund }}
          </el-descriptions-item>
          <el-descriptions-item label="注册地址">
            {{ memberInformation.cmpAddress }}
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :colon="false" class="margin-top" title="营业执照信息" :column="2" :size="''">
          <el-descriptions-item label="统一社会信用代码">
            {{ memberInformation.cmpUnicode }}
          </el-descriptions-item>
          <el-descriptions-item label="成立日期">
            {{ memberInformation.cmpDate }}
          </el-descriptions-item>
          <el-descriptions-item label="营业期限截止日">
            {{ memberInformation.cmpCloseDate || '长期' }}
          </el-descriptions-item>
          <el-descriptions-item label="登记机关">
            {{ memberInformation.cmpRegOrg }}
          </el-descriptions-item>
          <el-descriptions-item :span="2" label="公司类型">
            {{ memberInformation.cmpType }}
          </el-descriptions-item>
          <el-descriptions-item :span="2" label="营业执照电子版">
            <div class="electronicMaterial">
              <img :src="$fileUrl + memberInformation.businessLicense" :onerror="$errImage" alt="电子资料" @click="$refs.picturePresentation.dialogImageVisible=true;imgUrl=memberInformation.businessLicense">
              <!-- <a v-if="memberInformation.businessLicense" target="_blank"
                :href="$fileUrl + memberInformation.businessLicense">查看</a> -->
            </div>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :colon="false" class="margin-top" title="法人信息" :column="2" :size="''">
          <el-descriptions-item label="法人姓名">
            {{ memberInformation.leadName }}
          </el-descriptions-item>
          <el-descriptions-item label="法人身份证号">
            {{ memberInformation.leadPaperid }}
          </el-descriptions-item>
          <el-descriptions-item label="身份证有效期开始日">
            {{ memberInformation.leadIdcardSdate }}
          </el-descriptions-item>
          <el-descriptions-item label="身份证有效期截止日">
            {{ memberInformation.leadIdcardEdate }}
          </el-descriptions-item>
          <el-descriptions-item :span="2" label="法人身份证">
            <div class="identityCard">
              <div class="electronicMaterial">
                <img :src="$fileUrl + memberInformation.leadIdcardFront" :onerror="$errImage" alt="正面" @click="$refs.picturePresentation.dialogImageVisible=true;imgUrl=memberInformation.leadIdcardFront">
                <!-- <a v-if="memberInformation.leadIdcardFront" target="_blank"
                  :href="$fileUrl + memberInformation.leadIdcardFront">查看</a> -->
              </div>
              <div class="electronicMaterial">
                <img :src="$fileUrl + memberInformation.leadIdcardBack" :onerror="$errImage" alt="反面" @click="$refs.picturePresentation.dialogImageVisible=true;imgUrl=memberInformation.leadIdcardBack">
                <!-- <a v-if="memberInformation.leadIdcardBack" target="_blank"
                  :href="$fileUrl + memberInformation.leadIdcardBack">查看</a> -->
              </div>
            </div>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :colon="false" class="margin-top" title="管理员账号" :column="2" :size="''">
          <el-descriptions-item label="管理员账号">
            {{ memberInformation.userCode }}
          </el-descriptions-item>
          <el-descriptions-item label="管理员姓名">
            {{ memberInformation.userName }}
          </el-descriptions-item>
          <el-descriptions-item label="管理员手机号">
            {{ memberInformation.phoneNo }}
          </el-descriptions-item>
          <el-descriptions-item label="管理员身份证">
            {{ memberInformation.cardNo }}
          </el-descriptions-item>
        </el-descriptions>
      </el-tab-pane>
      <!-- 审核历史 -->
      <el-tab-pane label="认证及审核" name="fourth2">
        <!-- <h3 class="page-subtitle-shade">公司认证信息</h3> -->
        <el-descriptions :colon="false" class="margin-top" title="公司认证信息" :column="2" :size="''">
          <el-descriptions-item label="公司认证状态">
            {{ memberInformation.authState == '0' ? '未认证' : '认证成功' }}
          </el-descriptions-item>
          <el-descriptions-item label="电子签章账号">
            {{ memberInformation.cmpAccountId }}
          </el-descriptions-item>
          <el-descriptions-item label="当前签章人">
            {{ memberInformation.currEsignUser }}
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :colon="false" class="margin-top" title="公司审核信息" :column="2" :size="''">
          <el-descriptions-item :span="2" label="公司审核状态">
            <div v-for="item in accountStatus" :key="item.id" style="display:inline-block">
              <span v-if="memberInformation.cmpState == item.id">{{ item.name }}</span>
            </div>
          </el-descriptions-item>
          <el-descriptions-item class="reviewProgress" :span="2" label="审核进度" />
        </el-descriptions>
        <el-timeline :reverse="true">
          <el-timeline-item
            v-for="(activity, index) in approveArr"
            :key="index"
            :timestamp="timestampToTime(activity.approveTime)"
            placement="top"
          >
            <el-card>
              <h3>{{ activity.nodeName }}</h3>
              <p>审核人：{{ activity.userName }}</p>
              <p>
                审核结果：
                <span v-if="activity.approveState === '10'">通过</span>
                <span v-else>不通过</span>
              </p>
              <p>原因：{{ activity.approveExplain }}</p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
        <el-empty v-if="approveArr && approveArr.length === 0" description="暂无数据" />
      </el-tab-pane>
    </el-tabs>
    <!-- 审核弹窗 -->
    <Dialog ref="dialog" dialog-width="40%" :form-item="formItem" @getFormData="getFormData" @changeFormSelect="changeFormSelect" />
    <PicturePresentation ref="picturePresentation" :img-url="imgUrl" />
  </div>
</template>

<script>
import { getResourceByTask, cmpBaseinfoDetail, cmpApprovePage, cmpBaseinfoAudit } from '@/api/memberManagement'
import { accountStatus } from '@/systemConfiguration/index'
// import AccountInformation from './AccountInformation'
import Dialog from '@/components/Dialog.vue'
import PicturePresentation from '@/components/PicturePresentation.vue'
import { timestampToTime } from '@/utils/util'
export default {
  components: { Dialog, PicturePresentation },
  data() {
    return {
      formItem: [
        {
          label: '审核状态',
          type: 'select',
          value: 'approveResult',
          optionLabel: 'VARIABLE_NAME',
          optionId: 'VARIABLE_CODE',
          child: []
        },
        {
          label: '原因',
          type: 'input',
          value: 'reason',
          rules: 'none'
        }
      ],
      imgUrl: '',
      accountStatus,
      enterpriseRole: this.$store.getters.getDictionaryItem('SYS_TYPE'),
      controlButton: false,
      memberInformation: {},
      activeName: 'first',
      cmpCode: '',
      approveArr: [],
      resourceByTaskObj: {}
    }
  },
  mounted() {
    const { cmpCode, type } = this.$route.query
    this.cmpCode = cmpCode
    this.controlButton = !type
    this.resourceByTaskObj = this.$store.state.resourceByTaskObj || sessionStorage.getItem('resourceByTaskObj')
    this.initPage(cmpCode)
  },
  methods: {
    timestampToTime,
    initPage(cmpCode) {
      cmpBaseinfoDetail(cmpCode, res => {
        this.memberInformation = { ...res.data }
        // 因为角色名称是个数组  所以需要取他们的相同点进行加载
        if (this.memberInformation.cmpRoleList && this.memberInformation.cmpRoleList.length > 0) {
          this.memberInformation.cmpRole = ''
          this.enterpriseRole.forEach(val => {
            if (this.memberInformation.cmpRoleList.includes(val.dictId)) this.memberInformation.cmpRole += val.dictName + ' '
          })
        }
      })
      // 审核流程
      cmpApprovePage({ pageNum: 1, cmpCode, pageSize: 100000 }, res => {
        this.approveArr = [...res.data.pageData]
      })
    },
    // 确认审核按钮
    confirmAudit() {
      this.$refs.dialog.editFormVisible = true
      getResourceByTask(this.resourceByTaskObj, res => {
        this.formItem.forEach(item => {
          if (item.value === 'approveResult') {
            item.child = [...res.data]
          }
        })
      })
    },
    // 审核提交
    getFormData(value) {
      const obj = {
        ...value,
        applyCode: this.memberInformation.cmpCode,
        activiteId: this.resourceByTaskObj.activiteId,
        cmpState: this.memberInformation.cmpState,
        currentNodeId: this.resourceByTaskObj.currentNodeId
      }
      cmpBaseinfoAudit(obj, () => {
        this.$refs.dialog.editFormVisible = false
        this.$message.success('成功！')
        this.$router.go(-1)
      })
    },
    // 审核状态选择
    changeFormSelect(val) {
      if (val === 'approve_refuse') return delete this.formItem[1].rules
      if (val === 'approve_refuse') return this.formItem[1].rules
      this.$set(this.formItem[1], 'rules', 'none')
    }
  }
}
</script>

<style scoped>
.identityCard {
  display: flex;
}

.electronicMaterial {
  width: 60%;
  min-width: 240px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 32px;
}

.electronicMaterial img {
  height: 120px;
  width: 180px;
  border-radius: 2px;
}

.electronicMaterial a {
  color: rgb(26, 7, 136);
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  width: 48px;
}

.el-tabs>>>.el-tabs__content {
  padding: 0 16px 32px;
}

.el-descriptions /deep/ .el-descriptions__body {
  padding: 0 32px;
  color: #35383D
}

.el-descriptions /deep/ .el-descriptions-item__label:not(.is-bordered-label) {
  color: #93959A;
}

.el-descriptions__extra .el-button {
  position: absolute;
  right: 10px;
  top: 8px;
}
</style>
